<template>
  <div class="main d-flex justify-content-center">
    <div class="terms">
      <section class="mainarea">
        <h2 id="privacy" class="ttl">個人情報の取扱いについて</h2>
        <p>
          株式会社イード（以下、弊社という）はお客様からお預かりした個人情報の取扱について、下記のように定め、保護に努めます。
        </p>

        <h3 class="subttl">利用目的</h3>
        <p>
          お客様の本サービス利用契約を管理し、本サービスを提供するため
          本サービスに関するご案内やアンケートをお送りするため
        </p>
        <p>お客様のお問合せに回答するため</p>

        <h3 class="subttl">第三者への提供</h3>
        <p>
          弊社は法律で定められている場合を除いて、お客様の個人情報を当該本人の同意を得ず第三者に提供することはありません。
        </p>

        <h3 class="subttl">個人情報の取扱い業務の委託</h3>
        <p>
          弊社は事業運営上、お客様により良いサービスを提供するために業務の一部を外部に委託しており、業務委託先に対してお客様の個人情報を預けることがあります。この場合、個人情報を適切に取り扱っていると認められる委託先を選定し、契約等において個人情報の適正管理・機密保持などによりお客様の個人情報の漏洩防止に必要な事項を取決め、適切な管理を実施させます。
        </p>

        <h3 class="subttl">個人情報提出の任意性</h3>
        <p>
          お客様が弊社に対して個人情報を提出することは任意です。ただし、個人情報を提出されない場合には、弊社からの返信やサービスの実施ができない場合がありますので、あらかじめご了承ください。
        </p>

        <h3 class="subttl">個人情報の開示請求について</h3>
        <p>
          お客様には、貴殿の個人情報の利用目的の通知、開示、内容の訂正、追加又は削除、利用の停止、消去及び第三者への提供の停止を要求する権利があります。詳細につきましては下記の窓口までご連絡いただくか「<a
            href="https://www.iid.co.jp/company/pp.html#privacy02"
            target="_blank"
            >個人情報の取り扱いについて</a
          >」をご確認ください。
        </p>

        <h3 class="subttl">お問合せ先：個人情報問合せ窓口</h3>
        <p>
          e-mail ： Xprivacy-admin@iid.co.jp
          （先頭のXを除いたアドレスとなります）<br />
          個人情報保護管理者：人事総務部長
        </p>
      </section>
    </div>
    <MetaTags />
  </div>
</template>
<script>
export default {
  name: "HandlingPI",
};
</script>
<style scoped></style>
